<template>
  <div>
    <b-row>
      <b-col cols="1">
        <b-form-checkbox
          name="checkbox"
          class="text-base"
          :checked="value"
          @change="onInput"
          v-b-tooltip="{ customClass : 'tooltip'}"
          :title="helpText"
        >
        </b-form-checkbox>
      </b-col>
      <b-col cols="11" class="text-base">
        <div class="mt-1">{{ label }}</div>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    field: {
      type: String
    },
    label: {
      type: String
    },
    helpText: {
      type: String,
      default: ""
    }
  },
  methods: {
    onInput(value) {
      const data = {}
      data[this.field] = value;
      this.$store.dispatch(this.storeEditAction, data);
    }
  }
};
</script>

<style lang="scss" scoped>
  .tooltip::v-deep .tooltip-inner{
    max-width: 25em;
  }
</style>
